import { useUnit } from 'effector-react'
import React, { lazy, Suspense } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './App.module.scss'
import { userFx, userLanaguageEvent } from './features/app/model'
import { getGclid, storeGclidInLocalStorage } from './utils/gclid'
// not Lazily loaded components
import { Header_shadowgpt } from '@components'
import { Humanizer} from './pages/Landing'; 
// Lazy load components
const Footer = lazy(() => import('@components').then(m => ({ default: m.Footer })))

//static imports

// Add type safety for dynamic imports
const createLazyPage = <K extends keyof Omit<typeof import('@pages'), 'Humanizer'>>(componentName: K) => 
    lazy(() => import('@pages').then(m => ({ 
      default: m[componentName] 
    })))

const Auth = createLazyPage('Auth')
const Humanizer_shadowgpt = createLazyPage('Humanizer_shadowgpt')
const Humanizer_transform = createLazyPage('Humanizer_transform')
const Humanizer_gptzero = createLazyPage('Humanizer_gptzero')
const Humanizer_bypass = createLazyPage('Humanizer_bypass')
const Humanizer_aichanger = createLazyPage('Humanizer_aichanger')
const Humanizer_createundetectable = createLazyPage('Humanizer_createundetectable')

const ContentLoader = React.memo(() => (
    <div className={styles.contentLoader}>
      <div className={styles.loadingBar} />
      <div className={styles.loadingBar} />
      <div className={styles.loadingBar} />
    </div>
  ))

// Main pages
const PastRequests = createLazyPage('PastRequests')
const DodgeAiChekers = createLazyPage('DodgeAiChekers')
const ChooseUsSection = createLazyPage('ChooseUsSection')
const ShadowsReference = createLazyPage('ShadowsReference')
const ShadowGPTReview = createLazyPage('ShadowGPTReview')
const FAQBlock = createLazyPage('FAQBlock')
const PricingBlock = createLazyPage('PricingBlock')
const StayInShadowBlock = createLazyPage('StayInShadowBlock')
const GenerationBlock = createLazyPage('GenerationBlock')
const Pricing = createLazyPage('Pricing')
const ShadowGPT = createLazyPage('ShadowGPT')
const Billings = createLazyPage('Billings')
const Support = createLazyPage('Support')
const AccountDetails = createLazyPage('AccountDetails')
const History = createLazyPage('History')
const ErrorPage = createLazyPage('ErrorPage')
const TermsAndPrivicyPages = createLazyPage('TermsAndPrivicyPages')
const TermsAndConditions = createLazyPage('TermsAndConditions')
const PrivacyPolicy = createLazyPage('PrivacyPolicy')

// ShadowGPT variants
const PastRequests_shadowgpt = createLazyPage('PastRequests_shadowgpt')
const DodgeAiChekers_shadowgpt = createLazyPage('DodgeAiChekers_shadowgpt')
const ChooseUsSection_shadowgpt = createLazyPage('ChooseUsSection_shadowgpt')
const ShadowsReference_shadowgpt = createLazyPage('ShadowsReference_shadowgpt')
const ShadowGPTReview_shadowgpt = createLazyPage('ShadowGPTReview_shadowgpt')
const FAQBlock_shadowgpt = createLazyPage('FAQBlock_shadowgpt')
const StayInShadowBlock_shadowgpt = createLazyPage('StayInShadowBlock_shadowgpt')

// Bypass variants
const TextWall_bypass = createLazyPage('TextWall_bypass')
const FAQBlock_bypass = createLazyPage('FAQBlock_bypass')
const StayInShadowBlock_bypass = createLazyPage('StayInShadowBlock_bypass')

// GPTZero variants
const FAQBlock_gptzero = createLazyPage('FAQBlock_gptzero')
const TextWall_gptzero = createLazyPage('TextWall_gptzero')

// AI Changer variants
const FAQBlock_aichanger = createLazyPage('FAQBlock_aichanger')
const TextWall_aichanger = createLazyPage('TextWall_aichanger')

// Undetectable AI variants
const FAQBlock_createundetectable = createLazyPage('FAQBlock_createundetectable')
const TextWall_createundetectable = createLazyPage('TextWall_createundetectable')

// Transformer variants
const TextWall_transform = createLazyPage('TextWall_transform')
const FAQBlock_transform = createLazyPage('FAQBlock_transform')

const privateRoute = [
    {
        path: '/support',
        element: (
            <Suspense fallback={<ContentLoader />}>
                <Support />
            </Suspense>
        ),
    },
    {
        path: '/generation/',
        element: (
            <Suspense fallback={<ContentLoader />}>
                <GenerationBlock />
            </Suspense>
        ),
        children: [
            {
                path: '/generation/shadow-chat',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <ShadowGPT />
                    </Suspense>
                ),
            },
            {
                path: '/generation/billings',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <Billings />
                    </Suspense>
                ),
            },
            {
                path: '/generation/account-details',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <AccountDetails />
                    </Suspense>
                ),
            },
            {
                path: '/generation/history',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <History />
                    </Suspense>
                ),
            },
        ],
    },
]
const publicRoute = [
    {
        path: '/',
        element: (
          <>
            <Header_shadowgpt /> {/* Static */}
            <Humanizer /> {/* Static */}
            <Suspense fallback={<ContentLoader />}>
              <>
                <DodgeAiChekers />
                <ShadowsReference />
                <ShadowGPTReview />
                <PastRequests />
                <ChooseUsSection />
                <FAQBlock />
                <PricingBlock />
                <StayInShadowBlock />
                <Footer />
              </>
            </Suspense>
          </>
        ),
        errorElement: <ErrorPage />,
      },
    {
        path: '/shadowgpt',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_shadowgpt />
                <DodgeAiChekers_shadowgpt />
                <ShadowsReference_shadowgpt />
                <PastRequests_shadowgpt />
                <ShadowGPTReview_shadowgpt />
                <ChooseUsSection_shadowgpt />
                <FAQBlock_shadowgpt />
                <PricingBlock />
                <StayInShadowBlock_shadowgpt />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/bypass-ai-detection',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_bypass />
                <TextWall_bypass/>
                <FAQBlock_bypass/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/bypass-gptzero',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_gptzero />
                <TextWall_gptzero/>
                <FAQBlock_gptzero/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/ai-changer-to-human',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_aichanger />
                <TextWall_aichanger/>
                <FAQBlock_aichanger/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/create-undetectable-ai-content',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_createundetectable />
                <TextWall_createundetectable/>
                <FAQBlock_createundetectable/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/transform-your-writing',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_transform/>
                <TextWall_transform/>
                <FAQBlock_transform/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/pricing',
        element: <Pricing />,
    },
    {
        path: '/auth',
        element: <Auth />,
    },
    {
        path: '/terms',
        element: <TermsAndPrivicyPages />,
        children: [
            {
                element: <TermsAndConditions />,
                path: '/terms/conditions',
            },
            {
                element: <PrivacyPolicy />,
                path: '/terms/privacy',
            },
        ],
    },
    {
        path: '*',
        element: <Auth />,
    },
]

function App() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!);
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID })
    }

    let token = localStorage.getItem('token')
    const userRequest = useUnit(userFx)

    const { i18n } = useTranslation()

    React.useEffect(() => {
        const gclid = getGclid();
        if (gclid) {
            storeGclidInLocalStorage(gclid);
        }
    }, []);
    
    
    React.useEffect(() => {
        if (localStorage.getItem('token'))
            userRequest().then((res: any) => {
                if (Object.keys(res)) {
                    userLanaguageEvent(res.language.code.toLowerCase())
                    i18n.changeLanguage(res.language.code.toLowerCase())
                }
            })
    }, [token, userRequest])

    return (
        <>
            <div
                style={{ position: 'relative', top: '50px', zIndex: '100000' }}
            >
                <ToastContainer
                    position="top-center"
                    theme="dark"
                    autoClose={4000}
                    hideProgressBar={true}
                />
            </div>
            <div className={styles.background}>
                <RouterProvider
                    router={createBrowserRouter(
                        localStorage.getItem('token')?.length
                            ? [...publicRoute, ...privateRoute]
                            : [...publicRoute]
                    )}
                />
            </div>
        </>
    )
}

export default App
